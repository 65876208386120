var indexColumns = [{
  title: 'ID',
  dataIndex: 'id',
  key: 'id',
  width: '20%'
}, {
  title: '排序值',
  dataIndex: 'sort',
  key: 'sort',
  width: '10%'
}, {
  title: '课程合集',
  dataIndex: 'title',
  key: 'title',
  width: '20%'
}, {
  title: '级别',
  dataIndex: 'gradename',
  key: 'gradename',
  width: '10%'
}, {
  title: '标签',
  dataIndex: 'categorys',
  key: 'categorys',
  width: '20%',
  scopedSlots: {
    customRender: 'categorys'
  }
}, {
  title: '预览',
  dataIndex: 'coverurl',
  key: 'coverurl',
  width: '10%',
  scopedSlots: {
    customRender: 'coverurl'
  }
}, {
  title: '操作',
  dataIndex: 'action',
  key: 'action',
  width: '10%',
  scopedSlots: {
    customRender: 'action'
  }
}];
export { indexColumns };