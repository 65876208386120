var indexSearchKeys = [{
  key: "gradeid",
  label: "级别目录",
  placeholder: "请选择级别",
  required: false,
  rules: [],
  select: true,
  initialValue: ''
}, {
  key: "categoryid",
  label: "标签",
  placeholder: "请选择标签",
  required: false,
  rules: [],
  select: true,
  initialValue: ''
}, {
  key: "title",
  label: "课程合集",
  placeholder: "请输入课程合集",
  required: false,
  rules: [],
  input: true
}, {
  key: "button",
  label: "",
  placeholder: "",
  button: true
}];
export { indexSearchKeys };